import styled from 'styled-components'
import {theme} from '../../../tailwind.config'
import tw from "tailwind.macro"

const SSubMenu = styled.div`
  position: absolute;
  z-index: 3;
  top: 100%;
  right: -20px;
  border-radius: 6px;
  background: white;
  box-shadow: 0px 5px 24px rgba(0,0,0,0.15);
  transform: translateX(0) translateY(-10px) scale(0.95);
  transition: all 100ms ease;
  min-width: 580px;
  color: ${theme.colors.text} !important;


  ul.subMenuGroupPrimary > li {
    &:last-child > a { margin-bottom: 0px; }
    > a  {
      background: white;
      border-radius: 6px;
      color: ${theme.colors.text} !important;
      display: flex;
      align-items: top;
      margin-bottom: 10px;
      * {
        transition: 200ms;
      }
      .dropdown-link-name {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
      }
      .dropdown-link-description { 
        text-transform: none;
        font-weight: 300;
        ${tw`text-sm`}
        opacity: 0.70;
        line-height: 20px;
      }
      .dropdown-link-icon {
        width: 80px;
      }
      
      &:hover {
        > .dropdown-link-icon, > .dropdown-link-name {
          transform: translateX(10px)
        }
      }
    }
  }

  ul.menu--2col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > li {
      min-width: 50%;
    }
  }

  ul.subMenuGroupSecondary > li {
    > a {
      color: ${theme.colors.text} !important;
      display: flex;
      align-items: center;
      .dropdown-link-name {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 28px;
        margin-right: 10px;
      }
      * {
        transition: 200ms;
      }
      .dropdown-link-description { 
        text-transform: none;
        font-weight: 300;
        ${tw`text-sm`}
        opacity: 0.70;
        line-height: 20px;
        
      }
      .dropdown-link-icon {
        width: 40px;
      }

      &:hover {
        > .dropdown-link-icon, > .dropdown-link-name, > .dropdown-link-description  {
          transform: translateX(10px)
        }
      }
    }
  }

  .subMenuGroupTerciary {
    display: flex;
    padding: 0 1rem;
    padding-left: calc(2rem + 26px);
    justify-content: space-between;
    > div {
      //width: 50%;
    }
    .subMenuGroupTerciary__title {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.8rem;
      opacity: 0.5;
      padding-bottom: 10px;
      & > a.nav-link {
        padding: 0px;
        color: ${theme.colors.text};
      }
    }
    ul > li {
      pading-left: 0px;
      a.nav-link {
        font-weight: 400;
        padding: 0px;
        line-height: 1.85rem;
        color: ${theme.colors.text};
      }
    }
    a {
      transition: 100ms;
    }
    a:hover {
      color: #000000 !important;
    }
    
  }

  // -----
  @media(max-width: ${theme.screens.md.max}) {
    margin-left: 0 !important;
    right: 0 !important;
    min-width: auto;
    transform: none;
    ul.subMenuGroupPrimary > li {
    }
    ul.subMenuGroupSecondary > li {
    
    }
    .subMenuGroupTerciary {
      padding: 20px 0 !important;
    }
    ul.menu--2col {
      > li {
        width: 100%;
      }
    }
  }
`

const SLinkGroup = styled.div`
  background-color: ${props => props.alt ? '#f6f9fc' : 'transparent'};
  border-radius:4px;
  ${tw`p-5`}
`

const SMenu = styled.div`
  > ul > li {
    position: relative;
    > a {
      color: ${theme.colors.text};
      &:hover {
        color: ${theme.colors.text};
      }
    }
    > ${SSubMenu} {
      opacity: 0;
      visibility: hidden; 
    }
    &:hover {
      > ${SSubMenu} {
        transform: translateX(0) translateY(0px) scale(1);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .navigation-separator {
    ${tw`ml-6`}
  }

  @media(max-width: ${theme.screens.md.max}) {
    > ul > li > ${SSubMenu} {
      margin-left: 20px;
      margin-bottom: 0px;
      opacity: 1;
      position: relative;
      box-shadow: none;
      display: none;
      .subMenuGroupTerciary {
        padding: 0px;
        display: block;
        > div {
          &:first-child {
            margin-top: 0px;
          }
          margin-top: 1rem;
        }
        a { font-size: 1rem; line-height: 2rem; }
      }
      a, ${SLinkGroup} a {
        margin-bottom: 0px;
        align-items: center;
        .dropdown-link-name {
          text-transform: none;
        }
        .dropdown-link-icon {
          width: 40px;
          .gatsby-image-wrapper { width: 30px !important; max-height:30px;}
          .gatsby-image-wrapper img { max-width: 30px !important; max-height:30px; height: auto !important;}
        }
        .dropdown-link-description { 
          display: none;
        }
      }
      &.help-menu .subMenuGroupSecondary a{
        .dropdown-link-description { 
          font-size: 1rem;
          display: block;
        }
      }
      &:hover {
        > ${SSubMenu} {
          transform: none;
        }
      }
    }

    ${SLinkGroup} {
      padding: 0.5rem 0 0 0;
      background: transparent;
    }

    > ul > li.active > ${SSubMenu} {
      display: block;
      opacity: 1;
      visibility: visible;
      position: relative;
      box-shadow: none;
    }

    .navigation-separator {
      margin-left: 0;
    }
  }
`

const SArrow= styled.div`
  content: '';
  position: absolute;
  top: -7px;
  right: 40px;
  width: 0px; 
  height: 0px; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
`

export { SArrow, SMenu, SLinkGroup, SSubMenu }