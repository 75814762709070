import React, { useState } from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import logoImage from "images/logo.svg"
import { loginUrl, signupUrl } from "../../utils/urlHelper"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { Button } from 'components/ui'
import { SArrow, SMenu, SLinkGroup, SSubMenu } from './_styles';
import Image from "components/image"


const Navigation = ({ isDark }) => {
  const [isSticky, setisSticky] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [openedMenu, setOpenedMenu] = useState(null)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const toBeSticky = currPos.y < -200
      if (toBeSticky !== isSticky) setisSticky(toBeSticky)
    },
    [isSticky]
  )

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const toggleOpenedMenu = (menu) => {
      if (openedMenu === menu) setOpenedMenu(null)
      else setOpenedMenu(menu)
  }

  return (
    <div className={ classnames({'navigation': true, 'navigation--sticky': isSticky, 'navigation--open': isOpen })}>
      <div className="container">
        <div
          className={classnames({
            "navbar navbar-expand-lg": true,
            "navbar-hero": isDark,
          })}
        >
          <a href="/" className="navbar-logo">
            <img src={logoImage} alt="Simplebackups" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <SMenu className={classnames({'navbar-collapse collapse': true, 'show': isOpen})} id="navbarNav">
            <ul className="nav">
              <li className={classnames({'menu-group dropdown': true, 'active': (openedMenu === 'solutions')})} onClick={() => toggleOpenedMenu('solutions')}>
                <Link className="nav-link">
                  Solutions <i className="fa fa-caret-down" style={{fontSize: '12px'}}/>
                </Link>
                <SSubMenu className="solution-menu">
                  <SArrow/>
                  <SLinkGroup>
                    
                    <ul className="subMenuGroupSecondary menu--2col">
                    <li>
                        <Link to="/server-backup/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-server.png" style={{width: 26}} alt="Server and file backup" /></div>
                          <div className="dropdown-link-name">Server Backup</div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/mysql-backup/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-mysql-logo.png" style={{width: 26}} alt="MySQL backup" /></div>
                          <div className="dropdown-link-name">MySQL Backup</div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/mongodb-backup/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-mongodb-logo.png" style={{width: 15}} alt="MongoDb backup" /></div>
                          <div className="dropdown-link-name">MongoDB Backup</div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/postgresql-backup/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-postgres-logo.png" style={{width: 26}} alt="Postgres database backup" /></div>
                          <div className="dropdown-link-name">PostgreSQL Backup</div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/digitalocean-backup/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-do-logo.png" style={{'width': '26px'}} alt="DigitalOcean backup"/></div>
                          <div className="dropdown-link-name">DigitalOcean Backup</div>
                        </Link>
                      </li>
                    </ul>
                  </SLinkGroup>
                  <SLinkGroup alt>
                    <div className="subMenuGroupTerciary">
                      <div className="">
                        <div className="subMenuGroupTerciary__title">Store your backups on</div>
                        <ul className="menu--2col">
                          <li><Link to="/dropbox-backup-and-restore/" className="nav-link">Dropbox</Link></li>
                          <li><Link to="/aws-s3-backup-and-restore/" className="nav-link">Amazon S3</Link></li>
                          <li><Link to="/google-cloud-storage-gca-backup-and-restore/" className="nav-link">Google Cloud Storage</Link></li>
                          <li><Link to="/backblaze-backup-and-restore/" className="nav-link">Backblaze B2</Link></li>
                          <li><Link to="/features/" className="nav-link">SimpleStorage</Link></li>
                          <li><Link to="/digitalocean-spaces-backup-and-restore/" className="nav-link">DigitalOcean Spaces</Link></li>
                          <li><Link to="/ssh-backup-and-restore/" className="nav-link">SSH Server</Link></li>
                          <li><Link to="/wasabi-backup-and-restore/" className="nav-link">Wasabi</Link></li>
                          <li><Link to="/filebase-backup-and-restore/" className="nav-link">Filebase</Link></li>
                          {/*<li><Link to="#" className="nav-link">Azure Blob Storage <small>(soon)</small></Link></li>*/}
                        </ul>
                      </div>
                    </div>    
                  </SLinkGroup>
                  <SLinkGroup>
                    <ul className="subMenuGroupSecondary">
                      <li>
                        <Link to="/startup-backup-solution/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-startup.png" style={{width: 26}} alt="Backup solution for startups" /></div>
                          <div className="dropdown-link-name">For startups<div className="dropdown-link-description">Database and server backups for all your startup</div></div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/agency-backup-solution/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-agency.png" style={{width: 26}} alt="Backup solution for agencies" /></div>
                          <div className="dropdown-link-name">For agencies<div className="dropdown-link-description">All your clients' backups in one place</div></div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/developer-backup-solution/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-developer.png" style={{width: 26}} alt="Backup solution for developers" /></div>
                          <div className="dropdown-link-name">For developers<div className="dropdown-link-description">Automate every bit of backups for databases and servers™</div></div>
                        </Link>
                      </li>
                    </ul>
                  </SLinkGroup>
                </SSubMenu>
              </li>
              <li className="menu-group">
                <Link to="/features/" className="nav-link">
                  Features
                </Link>
              </li>
              <li className="menu-group">
                <Link to="/pricing/" className="nav-link">
                  Pricing
                </Link>
              </li>
              <li className={classnames({'menu-group dropdown': true, 'navigation-separator': true, 'active': (openedMenu === 'help')})} onClick={() => toggleOpenedMenu('help')}>
                <Link className="nav-link">Help <i className="fa fa-caret-down" style={{fontSize: '12px'}}/></Link>
                <SSubMenu className="help-menu">
                  <SArrow/>
                  <SLinkGroup>
                    <ul className="subMenuGroupPrimary">
                      <li>
                        <a href="https://support.simplebackups.com/en/" className="nav-link">
                          <div className="dropdown-link-icon">
                            <Image filename="menu-documentation.png" style={{width: 45}} alt="Support articles" />
                          </div>
                          <div className="dropdown-link-name">
                            Documentation
                            <div className="dropdown-link-description">Our guides to help you getting started</div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <Link to="/contact-us/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-contact.png" style={{'width': '45px'}} alt="Contact us"/></div>
                          <div className="dropdown-link-name">
                            Contact us
                            <div className="dropdown-link-description">Support and contact details</div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/" className="nav-link">
                          <div className="dropdown-link-icon"><Image filename="menu-about.png" style={{'width': '40px'}} alt="About us"/></div>
                          <div className="dropdown-link-name">
                            About us
                            <div className="dropdown-link-description">Meet the team.</div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </SLinkGroup>
                  <SLinkGroup alt>
                    <ul className="subMenuGroupSecondary">
                      <li>
                        <a target="_blank" href="/blog/the-ultimate-mysql-database-backup-script/" className="nav-link">
                          <div className="dropdown-link-description">The Ultimate MySQL Database Backup Script</div>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/blog/the-ultimate-mongodb-database-backup-script/" className="nav-link">
                          <div className="dropdown-link-description">The Ultimate MongoDB Database Backup Script</div>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/blog/the-ultimate-postgresql-database-backup-script/" className="nav-link">
                          <div className="dropdown-link-description">The Ultimate PostgreSQL Database Backup Script</div>
                        </a>
                      </li>
                     <li>
                        <a target="_blank" href="https://support.simplebackups.com/en/articles/3769307-how-to-configure-your-first-digitalocean-droplet-backup" className="nav-link">
                          <div className="dropdown-link-description">How to configure your first DigitalOcean droplet backup</div>
                        </a>
                      </li>
                    </ul>
                  </SLinkGroup>
                </SSubMenu>
              </li>
              <li className="menu-group">
                <Link to="/blog/" className="nav-link">
                  Blog
                </Link>
              </li>
              <li className="menu-group">
                <a href={loginUrl()} className="nav-link">
                  Login
                </a>
              </li>
            </ul>
            <Button className="btn-nav" color="green" size="medium" href={signupUrl({ sb_source: "website", sb_term: "navigation" })} >Create Backup<i className="fa fa-arrow-right"></i></Button>
          </SMenu>
        </div>
      </div>
    </div>
  )
}

export default Navigation
