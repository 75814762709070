import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import "typeface-muli"
import "typeface-lora"
import Navigation from "components/navigation/navigation"
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby"
import Footer from "./footer"

import "styles/pages/landing.scss"


const Layout = ({ children, isDark }) => {

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.12.1/css/all.css" integrity="sha384-TxKWSXbsweFt0o2WqfkfJRRNVaPdzXJ/YLqgStggBVRREXkwU7OKz+xXtqOU4u8+" crossorigin="anonymous"/>
      </Helmet>
      <Navigation isDark={isDark}/>
      {children}
      <CookieConsent
        location="bottom"
        buttonText="Close"
        cookieName="cookie_policy"
        style={{ background: "#dfe2e8", color: '20202D', fontSize: '12px' }}
        buttonStyle={{ backgroundColor: "#20202D", color: "#ffffff", fontSize: "13px", borderRadius: '4px', margin: '10px' }}
        contentStyle={{}}
        expires={150}
      >
        By continuing to use this site you consent to the use of cookies in accordance 
        with our <Link to="/cookie-policy/" className="">cookie policy</Link>.
      </CookieConsent>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
