import React from "react"
import logoImage from "images/logo.svg"
import { Link } from "gatsby"
import {supportUrl} from "../../utils/urlHelper";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container footer__main-container">
        <div className="row">
          <div className="col-12">
            <img src={logoImage} alt="logo" />
          </div>
        </div>
        <div className="row row-grid">
          <div className="col-md-5">
            <p className="mt-3">All-in-one website & database backups on auto-pilot.</p>
            <div className="">
              <div className="">
                <i className="fas fa-envelope footer__left__ico"></i>{" "}
                <a
                  href="mailto:support@simplebackups.com?subject=Hi!"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-style"
                >
                  {" "}
                  support@simplebackups.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="row row-grid">
              <div className="col-md-4">
                <div className="footer-title">Product</div>
                <div className="bd-highlight">
                  <ul>
                    <li><Link to="/features/" className="footer-link">Features</Link></li>
                    <li><Link to="/pricing/" className="footer-link">Pricing</Link></li>
                    <li><Link to="/affiliate-program/" className="footer-link">Affiliates</Link></li>
                    <li><Link to="/alternatives/" className="footer-link">Alternatives</Link></li>
                    <li><Link to="/why/" className="footer-link">Why us?</Link></li>
                    <li><Link to="/mysql-backup/" className="footer-link">MySQL Backups</Link></li>
                    <li><Link to="/mongodb-backup/" className="footer-link">MongoDB Backups</Link></li>
                    <li><Link to="/postgresql-backup/" className="footer-link">Postgres Backups</Link></li>
                    <li><Link to="/digitalocean-backup/" className="footer-link">DigitalOcean Backups</Link></li>
                    <li><Link to="/agency-backup-solution/" className="footer-link">Backups For Agencies</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-title">Company</div>
                <div className="bd-highlight">
                <ul>
                  <li><Link to="/terms-and-conditions/" className="footer-link">Terms</Link></li>
                  <li> <Link to="/privacy/" className="footer-link">Privacy</Link></li>
                  <li><Link to="/cookie-policy/" className="footer-link">Cookie Policy</Link></li>
                  <li> <Link to="/privacy/#GDPR" className="footer-link">GDPR Compliance</Link></li>
                  <li> <Link to="/contact-us/" className="footer-link">Contact Us</Link></li>
                <li> <Link to="/about/" className="footer-link">About</Link></li>
                </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-title">Resources</div>
                <div className="bd-highlight">
                  <div>
                    <ul>
                      <li><a href={supportUrl() + '/collections/1334888-frequently-asked-questions'} className="footer-link">FAQ</a></li>
                      <li><a href="/blog/" className="footer-link">Blog</a></li>
                      <li><a href={supportUrl()} className="footer-link">Support</a></li>
                      <li><a href="https://sourceforge.net/software/product/SimpleBackups/" target="_blank" rel="noopener noreferrer" className="footer-link">Reviews</a></li>
                      <li><a href="https://headwayapp.co/simplebackups-updates" className="footer-link">What's New</a></li>
                      <li><a href="https://www.digitalocean.com/community/tools/simplebackups-digitalocean-server-database-backups" target="_blank" rel="noopener noreferrer" className="footer-link">On DigitalOcean</a></li>
                      <li><a href={supportUrl() + '/collections/1346710-tutorials-how-tos'} className="footer-link">Tutorials & How Tos</a></li>
                    </ul>
                  </div>
                  <br />
                  {/*<div>*/}
                  {/*  <TwitterFollowButton screenName={"SimpleBackupsIO"} count={false} showCount={false} />*/}
                  {/*</div>*/}
                  <div>
                    <a
                      href="https://www.producthunt.com/posts/simplebackups?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-simplebackups"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=173978&theme=dark"
                        alt="SimpleBackups - Website & database backups with smart features and an API | Product Hunt Embed"
                        style={{ width: "250px", height: "54px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-grid">
          <div className="col-md-12">
            <small className="mt-2">
              Copyright {new Date().getFullYear()} simplebackups.com
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
